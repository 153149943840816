.positive-qty-row {
  background: #eef9fc;
}

.MuiDataGrid-cell[data-field="count"] {
  border: 1px solid #000;
  border-top: none;
  border-bottom: none;
  cursor: text;
}
